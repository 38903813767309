<template>
  <section class="section">
    <form class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" />

      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3">
            <router-link to="/admin/gerenciar-usuarios" class="has-text-grey">
              Gerenciamento de Usuários
            </router-link>
            >
            <span class="has-text-primary">
              {{ isNew ? 'Cadastrar' : 'Editar' }}
            </span>
          </h3>
        </div>
        <div v-if="!isNew" class="column is-1" style="min-width: fit-content">
          <b-button class="button is-primary is-fullwidth" icon-left="account-lock" expanded @click="resetPassword">
            Resetar Senha
          </b-button>
        </div>
      </div>

      <div v-if="!isNew" class="columns is-multiline">
        <b-field label="Tipo de Usuario" class="column is-half mb-0">
          <b-select v-model="form.usuarioTipoId" name="tipo-usuario" placeholder="Selecione" expanded required>
            <option v-for="usuario in tiposUsuario" :key="usuario.id" :value="usuario.id">
              {{ usuario.tipo }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Nome" class="column is-half mb-0">
          <b-input type="text" v-model="form.nome" placeholder="Digite" required />
        </b-field>
        <b-field label="Login" class="column is-half mb-0">
          <b-input type="text" v-model="form.login" placeholder="Digite" required />
        </b-field>
        <b-field label="Email" class="column is-half mb-0">
          <b-input type="email" v-model="form.email" placeholder="Digite" required />
        </b-field>
      </div>

      <div v-else style="margin-bottom: 20px">
        <div class="columns is-multiline">
          <b-field label="Tipo de Usuario" class="column is-half mb-0">
            <b-select v-model="form.usuarioTipoId" name="tipo-usuario" placeholder="Selecione" expanded required>
              <option v-for="usuario in tiposUsuario" :key="usuario.id" :value="usuario.id">
                {{ usuario.tipo }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="columns is-multiline">
          <b-field label="Nome" class="column is-half mb-0">
            <b-input type="text" v-model="form.nome" placeholder="Digite" required />
          </b-field>
          <b-field label="Login" class="column is-half mb-0">
            <b-input type="text" v-model="form.login" placeholder="Digite" required />
          </b-field>
          <b-field label="Email" class="column is-half mb-0">
            <b-input type="email" v-model="form.email" placeholder="Digite" required />
          </b-field>
          <b-field label="Senha" class="column is-half mb-0">
            <b-input type="password" v-model="form.senha" placeholder="Digite" password-reveal required />
          </b-field>
        </div>
      </div>

      <b-button type="is-primary" tag="button" native-type="submit" label="Salvar" expanded />
    </form>
  </section>
</template>

<script>
import usuarioService from '@/services/usuarioService';
import usuarioTipoService from '@/services/usuarioTipoService';

export default {
  name: 'EditUsuario',
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {},
      tiposUsuario: [],
    };
  },
  computed: {
    isNew() {
      return !this.id;
    },
  },
  async created() {
    await this.getTipoUsuario();

    if (!this.isNew) {
      this.isLoading = true;
      usuarioService
        .getById(this.id)
        .then(({ data }) => {
          const form = { ...data, usuarioTipoId: data.usuarioTipo?.id };
          this.form = form;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    async getTipoUsuario() {
      await usuarioTipoService.get().then(({ data }) => {
        this.tiposUsuario = data;
      });
    },
    async handleSubmit(event) {
      event.preventDefault();

      this.isLoading = true;

      try {
        if (this.isNew) {
          await usuarioService.post(this.form).then(() => {
            this.$buefy.snackbar.open('Usuario cadastrado com sucesso');
            this.$router.push('/admin/gerenciar-usuarios');
          });
        } else {
          await usuarioService.put(this.form).then(() => {
            this.$buefy.snackbar.open('Usuario editado com sucesso');
            this.$router.push('/admin/gerenciar-usuarios');
          });
        }
      } catch {
        const action = this.isNew ? 'cadastrar' : 'editar';

        this.$buefy.snackbar.open({
          message: `Erro ao ${action} usuario`,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
    resetPassword() {
      this.$buefy.dialog.confirm({
        title: 'Resetar Senha',
        message: 'Se deseja alterar a senha deste usuário clique no <b>Resetar Senha</b> e vamos enviar uma nova senha no e-mail cadastrado.',
        confirmText: 'Resetar Senha',
        onConfirm: () =>
          usuarioService.resetarSenha(this.id).then(() => {
            this.$buefy.snackbar.open(`Email enviado com sucesso`);
          }),
      });
    },
  },
};
</script>
